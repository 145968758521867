import React from 'react'
import './OrderOnline.css'
import { images } from '../../constants';

function OrderOnline() {
  return (
    <div className='app__order-online'>
        <h1>Under Construction...</h1>
    </div>
  )
}

export default OrderOnline;